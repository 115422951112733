import { graphql } from '@/__generated__';

export const TestLevelBySlugQuery = graphql(`
  query TestLevelBySlugQuery($slug: String!) {
    testLevelBySlug(slug: $slug) {
      ...TestLevel
    }
  }
`);

export const TestLevelQuery = graphql(`
  query TestLevelQuery($id: ID!) {
    testLevel(id: $id) {
      id: testLevelId
      slug
      name
      playlistId
      description
      descriptionEn
    }
  }
`);

export const GetListTheme = graphql(`
  query GetListThemes($first: Int, $filterBy: ThemeFilter) {
    themes(first: $first, filterBy: $filterBy) {
      edges {
        node {
          id
          themeId
          createdAt
          updatedAt
          name
          nameEn
          imageId
          type
          bookOneId
          bookTwoId
          backgroundId
          bingoId
          progressId
          iconLeftId
          iconRightId
          blockLeft
          blockRight
          progressColor
          iconPrevId
          iconNextId
          borderProgress
          isDefault
          disabled
          imagePreviewId
          imageTitleId
        }
        cursor
      }
      totalCount
    }
  }
`);

export const TestLevelCheckPointsFullQuery = graphql(`
  query CheckPointsFullQuery(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $filterBy: CheckPointFilter
  ) {
    checkPoints: checkPointsFull(
      first: $first
      after: $after
      last: $last
      before: $before
      filterBy: $filterBy
      orderBy: { key: TEST_LEVEL_ORDER }
    ) {
      edges {
        cursor
        node {
          ...TestLevelCheckPoint
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const TestLevelFragment = graphql(/* GraphQL */ `
  fragment TestLevel on TestLevel {
    id: testLevelId
    slug
    name
    playlistId
    typeTestLevel
    description
    descriptionEn
  }
  fragment UserError on UserError {
    message
    code
    field
    param {
      value
    }
  }
`);

export const TestLevelCheckPointFragment = graphql(/* GraphQL */ `
  fragment TestLevelCheckPoint on CheckPoint {
    id: checkPointId
    name
    nameEn
    type
    meta {
      externalUrl
    }
    viewerCompleted
    isPremium
    partsCount
    testCount
    time
    viewerCanAccess
    viewerIsSubscribing
    speakingParts {
      id
      speakingPartId
      partType
    }
    testLevel {
      id
      name
      slug
    }
  }
`);

export const MoreInfoCheckPointQuery = graphql(/* GraphQL */ `
  query MoreInfoCheckPointQuery($id: ID!) {
    checkPoint(id: $id) {
      id
      viewerCanAccess
      name
      nameEn
      checkPointId
      cpType
      type
      examStructures {
        structureType {
          name
          value
        }
      }
      sectionCheckPoints {
        id
        parentStructureType
        checkPointId
        parentPartOrder
      }
    }
  }
`);
export const RecordScoreAttemptFragment = graphql(`
  fragment RecordScorePronunciation on RecordScorePronunciation {
    generalScore
    intonationScore
    score
    decision
    highlightWords {
      startIndex
      endIndex
      text
      ipa
      nativenessScore
      decision
      audioUrl
      phonemes {
        startIndex
        endIndex
        text
        ipa
        nativenessScore
        decision
      }
    }
  }
  fragment RecordScoreGrammar on RecordScoreGrammar {
    messages
    score
    decision
    highlightItems {
      suggestion
      startIndex
      original
      endIndex
    }
  }
  fragment RecordScoreVocabulary on RecordScoreVocabulary {
    answerKeyScore
    score
    decision
    cefrScore
    cefrDistribution {
      cefrLevel
      score
    }
    highlightKeywords {
      startIndex
      endIndex
      keyword
    }
  }
  fragment RecordScoreAttemptFragment on RecordScore {
    transcript
    suggestions {
      audioId
      id
      text
    }
    vocabularyGrammarScore
    pronunciationScore
    interactionScore
    vocabularyGrammarScorePercent
    vocabularyGrammarDecision
    interactionScorePercent
    pronunciationScore
    interactionScore
    overallScore
    coherence {
      decision
      score
    }
    coherenceScore
    vocabulary {
      ...RecordScoreVocabulary
    }
    grammar {
      score
      decision
      highlightItems {
        startIndex
        endIndex
        original
        suggestion
      }
    }
    pronunciation {
      ...RecordScorePronunciation
    }
    interaction {
      receptionResponding
      fluencyPromptness
      receptionDecision
      fluencyDecision
      fluency {
        wordsPerMinute
      }
    }
  }
`);
export const IeltsSpeakingQuestionFragment = graphql(/* GraphQL */ `
  fragment IeltsSpeakingQuestion on IeltsSpeakingQuestion {
    id
    title
    sampleAnswerHtml
    sampleAnswerAudio {
      id
      url
      subtitle
    }
    timeToSpeak
    timeToThink
    audio {
      id
      url
    }
    contentHtml
    ieltsSpeakingQuestionId
    name
  }
`);

export const IeltsSpeakingCheckPointsQuery = graphql(`
  query CheckPointIeltsQuery($id: ID!) {
    checkPoint(id: $id) {
      id
      name
      nameEn
      description
      descriptionEn
      diamond
      cpType
      viewerCanAccess
      sectionCheckPoints {
        id
        parentStructureType
        checkPointId
        parentPartOrder
      }
      checkPointIeltsSpeakingParts {
        node {
          id
          expiredTime
          order
          defaultShowQuestionContents
          info {
            id
            title
            description
            introductionAudio {
              id
              url
            }
            introductionText
          }
          ieltsSpeakingPartQuestions {
            node {
              ...IeltsSpeakingQuestion
            }
          }
        }
      }
    }
  }
`);

export const ExamCheckPointFullFragment = graphql(/* GraphQL */ `
  fragment ExamCheckPointFull on CheckPoint {
    id
    viewerCanAccess
    name
    nameEn
    checkPointId
    cpType
    type
    testLevel {
      ...TestLevel
    }
    examStructures {
      structureType {
        name
        value
      }
      parts {
        order
        node {
          examPartId
          totalMarks
          id
          maxScore
          answerExplanationHTML
          answerExplanationHTMLEn
          partType
          body {
            contentImageId
            contentImagePrompt
          }
          contents {
            id
            body {
              formattedText {
                text
                format
                label
                metadataText
              }
              images {
                imageId
                label
                position
              }
            }
          }
          questions {
            isExample
            id
            order
            title
            answerExplanationHTML
            answerExplanationHTMLEn
            body {
              videoUrl
              audioId
              point {
                id
                left
                position
                top
              }
              audioSubtitle
              videoPrompt
              exampleAnswers
            }
            details {
              id
              labelOrder
              order
              prefix
              prefix
              detail {
                formattedText {
                  format
                  label
                  metadataText
                  text
                }
                textIndexes {
                  text
                  index
                }
                valueType
                box {
                  height
                  left
                  top
                  width
                }
              }
              result {
                isSelected
                value
                formattedValue {
                  format
                  text
                }
              }
            }
            titlePrefix {
              format
              label
              metadataText
              text
            }
            detailPrefix {
              format
              label
              metadataText
              text
            }
            content {
              format
              label
              metadataText
              text
            }
            choices {
              format
              label
              metadataText
              text
            }
          }
          time
          title
          structureType
        }
        miniCheckPointId
      }
      totalMarks
      time
      maxScore
    }
    sectionCheckPoints {
      id
      parentStructureType
      checkPointId
      parentPartOrder
    }
  }
`);

export const ExamPartAttemptFragment = graphql(`
  fragment ExamPartAttempt on ExamPartAttempt {
    id
    examPartAttemptId
    score
    maxScore
    part {
      id
      examPartId
      title
      partType
      structureType
      maxScore
      questions {
        id
        title
        order
      }
      time
    }
    position
    questionDetailAttempts {
      id
      examQuestionDetailAttemptId
      answer {
        value
        formattedValue {
          text
          format
          label
          metadataText
        }
        isSelected
        valueType
        recordId
      }
      isCorrect
      result {
        value
        formattedValue {
          text
          format
          label
          metadataText
        }
        recordId
        isSelected
        valueType
      }
      questionDetail {
        id
        prefix
      }
    }
  }
`);

export const ExamStructureAttemptsFragment = graphql(`
  fragment ExamStructureAttempts on ExamStructureAttempt {
    totalCorrectMarks
    structureType {
      value
      name
    }
    partAttempts {
      ...ExamPartAttempt
    }
  }
`);

export const TestLevelsWithFilter = graphql(`
  query TestLevelsWithFilter($filterBy: TestLevelFilter) {
    testLevels(first: 50, filterBy: $filterBy) {
      nodes {
        ...TestLevel
      }
    }
  }
`);

export const SubmitExamQuestionDetailAttemptMutation = graphql(`
  mutation SubmitExamQuestionDetailMutation($input: ExamSubmitPartAttemptInput!) {
    examSubmitPartAttempt(input: $input) {
      userErrors {
        ...UserError
      }
    }
  }
`);

export const SignUpViewSignUpMutation = graphql(`
  mutation SignUpViewSignUpMutation($input: ViewerSignUpInput!) {
    viewerSignUp(input: $input) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

export const ViewerXpSummaries = graphql(`
  query ViewerXpSummaries(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $filterBy: LearnerXpFilter
  ) {
    viewerXpSummaries(
      first: $first
      after: $after
      last: $last
      before: $before
      filterBy: $filterBy
    ) {
      nodes {
        date
        id
        learnerXpId
      }
    }
  }
`);

export const GetPlayerInfoChallengeByCodeQuery = graphql(`
  query GetPlayersInfoChallengeByCode($code: String!) {
    challengeByCode(code: $code) {
      id: challengeId
      code
      learnerProfile {
        id
        learnerProfileId
        displayName
      }
      players(first: 250) {
        totalCount
        edges {
          node {
            id
            challengePlayerId
            joinedAt
            updatedAt
            learnerProfile {
              id: learnerProfileId
              learnerProfileId
              displayName
              avatarUrl
              frameImage {
                id
                url
              }
            }
            attempt {
              id
              grade
              totalScore
            }
          }
        }
      }
    }
  }
`);

export const GetViewerAchievements = graphql(`
  query ViewerAchievements {
    viewerAchievements {
      id
      learnerAchievementId
      level
      name
      seen
      tier
      value
      dateReceived
      nextLevelValue
      maxLevel
    }
  }
`);

export const ViewerUpdateSeenAchievement = graphql(`
  mutation ViewerSeenAchievements($inputs: [ViewerSeenAchievementInput!]!) {
    viewerSeenAchievements(inputs: $inputs) {
      userErrors {
        code
        field
      }
    }
  }
`);
export const ExamDomain = graphql(`
  query ExamDomain($domain: String!) {
    partnerDomain(domain: $domain) {
      domainName
      id
      meta {
        loginTitle
        logoImage {
          id
          url
        }
        examBackgroundImage {
          url
          id
        }
        examLogoImage {
          url
          id
        }
      }
    }
  }
`);
export const ChallengeEndMutation = graphql(`
  mutation ChallengeEnd($input: ChallengeEndInput!) {
    challengeEnd(input: $input) {
      userErrors {
        code
        message
      }
    }
  }
`);
export const GetInfoCheckpointDetail = graphql(/* GraphQL */ `
  query GetInfoCheckpointDetail(
    $id: ID!
    $challengeCode: String!
    $challengeCodeResume: String
    $isChallengeMode: Boolean = false
  ) {
    checkPoint(id: $id) {
      ...ExamCheckPointFull
      viewerLatestResumableAttempt(challengeCode: $challengeCodeResume) {
        currentExamPart {
          id
          examPartId
        }
        currentStructure
        currentSectionExpiresAt
        userAttempt {
          id
          userAttemptId
          createdAt
          submittedAt
          examStructureAttempts {
            ...ExamStructureAttempts
          }
        }
      }
    }
    challengeByCode(code: $challengeCode) @include(if: $isChallengeMode) {
      code
      id: challengeId
      createdAt
      isLimit
      isHiddenName
      endAt
      name
      checkPoint {
        id: checkPointId
        name
        nameEn
      }
    }
  }
`);
export const GetResultUserAttempt = graphql(`
  query GetResultUserAttempt($id: ID!) {
    userAttempt(id: $id) {
      id
      createdAt
      submittedAt
      userAttemptId
      checkPoint {
        ...ExamCheckPointFull
      }
      examStructureAttempts {
        ...ExamStructureAttempts
      }
    }
  }
`);

export const EndExamMobilePlacementestMutation = graphql(`
  mutation ExamEndMobilePlacementestMutation($input: ExamEndAttemptInput!) {
    examEndAttempt(input: $input) {
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

export const PracticePartFragment = graphql(`
  fragment PracticePartFragment on PracticePart {
    id
    practicePartId
    position
    time
    title
    type
    answerExplanationHtml
    answerExplanationHtmlEn
    score
    body {
      query {
        videoUrl
        imageUrl
        image {
          id
          url
          label
        }
        audio {
          id
          url
        }
        contentHtml
      }
      details {
        id
        multipleCorrectAnswers
        options {
          id
          correctAnswer
          text
          image {
            id
            url
            label
          }
        }
      }
    }
  }
`);

export const PracticeCheckPoint = graphql(`
  query PracticeCheckPoint($checkPointId: ID!) {
    checkPoint(id: $checkPointId) {
      id
      checkPointId
      skills
      time
      maxScore
      name
      nameEn
      viewerCanAccess
      status
      practiceParts {
        ...PracticePartFragment
      }
    }
  }
`);

export const PracticeAttemptReview = graphql(`
  query PracticeAttemptReview($userAttemptId: ID!) {
    userAttempt(id: $userAttemptId) {
      id
      userAttemptId
      totalMaxScore
      totalScore
      checkPoint {
        id
        checkPointId
        skills
        time
        maxScore
        name
        nameEn
        viewerCanAccess
        practiceParts {
          ...PracticePartFragment
        }
      }
      practicePartAttempts {
        id
        part {
          id
          practicePartId
        }
        practicePartAttemptId
        correctMark
        detailAnswers {
          text
          optionIds
          detailId
          correctOptionIDs
        }
      }
    }
  }
`);
